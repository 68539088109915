import '../css/clients.scss';
import clients from '../store/clients'
import ClientItem from './clientItem';

export default function ClientSection() {
    return (
        <section className="clients">
            <div className="wrap">
                <div className="wrap_block">
                    <h2 className='section_title'>Наши клиенты</h2>
                    <ul className="client_item_block">
                        {clients.map((client, i) => <ClientItem key={i} image={client}/>)}
                    </ul>
                </div>
            </div>
        </section>
    )
}