import '../css/contactsPage.scss'

const ContactsPage = () => {
    return(
        <section className="contacts_page">
            <div className="wrap">
                <div className="wrap_block">
                    <h2 className="section_title">Контакты</h2>
                    <div className="contacts_block">
                        <div className="details">
                            <div className="contact_item">
                                <h3 className="item_title">Телефон</h3>
                                <a className="item_content" href="tel:88005005791">+7 (800) 500-57-91</a>
                            </div>
                            <div className="contact_item">
                                <h3 className="item_title">Почта</h3>
                                <a className="item_content" href="mailto:info@it-doc.ru">info@it-doc.ru</a>
                            </div>
                            <div className="contact_item">
                                <h3 className="item_title">Адрес</h3>
                                <span className="item_content">Московская обл., г. Балашиха, мкр. Железнодорожный, ул. Октябрьская, владение 1, корп. 1, помещение 2</span>
                            </div>
                            <div className="contact_item">
                                <h3 className="item_title">Реквизиты</h3>
                                <span className="item_content">ИНН/КПП 5012108833/ 501201001</span>
                            </div>
                        </div>
                        <div className="map">
                            <div style={{position:"relative", overflow:"hidden", width: '100%'}}>
                                <a href="https://yandex.ru/maps/org/it_doc/13893638266/?utm_medium=mapframe&utm_source=maps" style={{color:"#eee", fontSize:"12px", position:'absolute', top:"0px"}}>IT Doc</a>
                                <a href="https://yandex.ru/maps/10716/balashiha/category/it_company/184106174/?utm_medium=mapframe&utm_source=maps" style={{color:"#eee", fontSize:"12px", position:"absolute", top:"14px"}}>IT-компания в Балашихе</a>
                                <a href="https://yandex.ru/maps/10716/balashiha/category/software_companies/184105776/?utm_medium=mapframe&utm_source=maps" style={{color:"#eee", fontSize:"12px", position:"absolute", top:"28px"}}>Программное обеспечение в Балашихе</a>
                                <iframe src="https://yandex.ru/map-widget/v1/?ll=38.177083%2C55.812223&mode=search&oid=13893638266&ol=biz&sll=37.649739%2C55.810354&source=serp_navig&text=it%20doc&z=9" height="600" frameBorder="none" allowFullScreen={true} style={{position:"relative"}}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactsPage