let terms = [
    {
        title:'Настольный вариант',
        p:'Предназначен для использования в условиях кабинета или офиса регламентированным кругом лиц',
        img: '/images/services/term-development2.jpg',
        class:'terms_block_top'
    },
    {
        title:'Антивандальный вариант',
        p:'Имеет металлический корпус из стали толщиной 1,5 мм для размещения в условиях массового использования в общедоступных местах',
        img: '/images/services/term-development1.jpg',
        class:'terms_block_bottom'
    }
]

export default terms