import { useState } from 'react'
import '../css/priceItem.scss'

const PriceItem = (props) => {
    const [tableOpen, setTableOpen] = useState(false)
    return (
        <div className='price_block'>
            <div className='title' onClick={() => setTableOpen(!tableOpen)}>
                <h3>{props.price.title}</h3>
                <img src='images/arrow.svg' className='title_img' style={tableOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} />
            </div>
                {
                    tableOpen ?
                        <table className='price_table'>
                            <thead>
                                <tr>
                                    <th>Наименование работ</th>
                                    <th>Стоимость работ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(props.price.table).map((row, n) => <tr key={n}><td>{row}</td><td>{props.price.table[row]}</td></tr>)
                                }
                            </tbody>
                        </table>
                        :
                        null
                }
        </div>
    )
}

export default PriceItem