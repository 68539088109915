import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./pages/mainPage";
import Layout from "./components/layout";
import './css/app.scss';
import PricePage from "./pages/pricePage";
import ServicePage from "./pages/servicePage";
import ProductPage from "./pages/productPage";
import BlogPage from "./pages/blogPage";
import ArticlePage from "./pages/articlePage";
import ContactsPage from "./pages/contactsPage";
import FormReviewPage from "./pages/formReviewPage";
import TmPage from "./pages/support/tmPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route path='/' element={<MainPage />}/>
          <Route path='/prices' element={<PricePage />} />
          <Route path='/services/:service' element={<ServicePage />} />
          <Route path='/products/:product' element={<ProductPage />} />
          <Route path='/blog' element={<BlogPage />} />
          <Route path='/blog/:link' element={<ArticlePage />} />
          <Route path='/contacts' element={<ContactsPage />} />
          <Route path='/support/tm' element={<TmPage />} />
        </Route>
        <Route path='/forms/review/:id/:pass' element={<FormReviewPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
