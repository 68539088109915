let products_items = [
    {
        name: 'godoc',
        image: 'images/products/1.svg',
        title: 'Система Go Doc'
    },
    {
        name: 'doctors_godoc',
        image: 'images/products/2.svg',
        title: 'Приложение Go Doc для врачей'
    },
    {
        name: 'clients_godoc',
        image: 'images/products/3.svg',
        title: 'Приложение Go Doc для клиентов'
    },
    {
        name: 'mis_godoc',
        image: 'images/products/4.svg',
        title: 'МИС Go Doc'
    },
    {
        name: 'terms',
        image: 'images/products/5.svg',
        title: 'Терминалы для предрейсовых осмотров'
    },
]

export default products_items