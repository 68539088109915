const articles = [
    {
        name:'Статья 1',
        category: 'Полезные советы',
        link: 'link1',
        date: '02.02.2023',
        img: '/images/services/outsourcing.jpg',
        content: [
            {
                text: 'Равным образом реализация намеченных плановых заданий позволяет оценить значение системы обучения кадров, соответствует насущным потребностям. Не следует, однако забывать, что начало повседневной работы по формированию позиции позволяет выполнять важные задания по разработке соответствующий условий активизации. Товарищи! постоянное информационно-пропагандистское обеспечение нашей деятельности требуют от нас анализа соответствующий условий активизации. Равным образом постоянное информационно-пропагандистское обеспечение нашей деятельности в значительной степени обуславливает создание соответствующий условий активизации.',
                type: 'p'
            },
            {
                text: 'С другой стороны укрепление и развитие структуры представляет собой интересный эксперимент проверки модели развития. Задача организации, в особенности же постоянное информационно-пропагандистское обеспечение нашей деятельности требуют определения и уточнения соответствующий условий активизации. Не следует, однако забывать, что новая модель организационной деятельности играет важную роль в формировании дальнейших направлений развития.',
                type: 'p'
            },
            {
                text: 'Равным образом рамки и место обучения кадров требуют определения и уточнения дальнейших направлений развития.',
                type: 'quote'
            },
            {
                text: 'Не следует, однако забывать, что дальнейшее развитие различных форм деятельности способствует подготовки и реализации существенных финансовых и административных условий. Повседневная практика показывает, что постоянный количественный рост и сфера нашей активности влечет за собой процесс внедрения и модернизации систем массового участия. Не следует, однако забывать, что начало повседневной работы по формированию позиции представляет собой интересный эксперимент проверки существенных финансовых и административных условий. Товарищи! постоянное информационно-пропагандистское обеспечение нашей деятельности в значительной степени обуславливает создание форм развития.',
                type: 'p'
            },
            {
                text: 'Заголовок 1',
                type: 'title'
            },
            {
                text: 'С другой стороны консультация с широким активом в значительной степени обуславливает создание позиций, занимаемых участниками в отношении поставленных задач. Разнообразный и богатый опыт рамки и место обучения кадров представляет собой интересный эксперимент проверки позиций, занимаемых участниками в отношении поставленных задач. Значимость этих проблем настолько очевидна, что консультация с широким активом позволяет выполнять важные задания по разработке модели развития. С другой стороны постоянный количественный рост и сфера нашей активности требуют от нас анализа дальнейших направлений развития. Повседневная практика показывает, что новая модель организационной деятельности позволяет оценить значение дальнейших направлений развития.',
                type: 'p'
            },
            {
                text: '/images/logo.png',
                type: 'img',
                author: 'Автор 1',
                title: 'Название картинки'
            },
            {
                text: 'Идейные соображения высшего порядка, а также сложившаяся структура организации способствует подготовки и реализации модели развития. Идейные соображения высшего порядка, а также новая модель организационной деятельности представляет собой интересный эксперимент проверки существенных финансовых и административных условий. Товарищи! начало повседневной работы по формированию позиции в значительной степени обуславливает создание новых предложений. Таким образом сложившаяся структура организации позволяет оценить значение систем массового участия. Таким образом постоянный количественный рост и сфера нашей активности способствует подготовки и реализации дальнейших направлений развития. Не следует, однако забывать, что рамки и место обучения кадров требуют определения и уточнения систем массового участия.',
                type: 'p'
            },
        ]
    },
    {
        name:'Статья 2',
        category: 'Технологии',
        link: 'link2',
        p: ['p1', 'p2'],
        date: '05.02.2023',
        img: ''
    },
    {
        name:'Статья 3',
        category: 'Полезные советы',
        link: 'link3',
        p: ['p1', 'p2'],
        date: '07.02.2022',
        img: ''
    },
    {
        name:'Статья 4',
        category: 'Технологии',
        link: 'link4',
        p: ['p1', 'p2'],
        date: '25.02.2023',
        img: ''
    },
    {
        name:'Статья 5',
        category: 'Терминалы',
        link: 'link5',
        p: ['p1', 'p2'],
        date: '03.08.2023',
        img: ''
    },
    {
        name:'Статья 6',
        category: 'Полезные советы',
        link: 'link6',
        p: ['p1', 'p2'],
        date: '10.04.2024',
        img: ''
    },
    {
        name:'Статья 7',
        category: 'Полезные советы',
        link: 'link7',
        p: ['p1', 'p2'],
        date: '11.02.2023',
        img: ''
    },
    {
        name:'Статья 8',
        category: 'Технологии',
        link: 'link8',
        p: ['p1', 'p2'],
        date: '11.02.2023',
        img: ''
    },
    {
        name:'Статья 9',
        category: 'Полезные советы',
        link: 'link9',
        p: ['p1', 'p2'],
        date: '12.02.2022',
        img: ''
    },
    {
        name:'Статья 10',
        category: 'Технологии',
        link: 'link10',
        p: ['p1', 'p2'],
        date: '26.02.2023',
        img: ''
    },
    {
        name:'Статья 11',
        category: 'Терминалы',
        link: 'link11',
        p: ['p1', 'p2'],
        date: '15.08.2023',
        img: ''
    },
    {
        name:'Статья 12',
        category: 'Полезные советы',
        link: 'link12',
        p: ['p1', 'p2'],
        date: '17.04.2024',
        img: ''
    },
]

export default articles