import { Link } from "react-router-dom";

export default function DesktopMenu(){
    return(
        <nav className="menu_block">
        <Link to='/#about'><div className="menu">О нас</div></Link>
        <Link to='/#services'><div className="menu">Услуги</div></Link>
        <Link to='/#products'><div className="menu">Продукты</div></Link>
        <Link to='/#form'><div className="menu">Заявка</div></Link>
        <Link to='prices'><div className="menu">Стоимость услуг</div></Link>
        <Link to='contacts'><div className="menu">Контакты</div></Link>
    </nav>
    )
}