import '../css/productItem.scss';

export default function ProductItem(props) {
    return (
        <dl className="product_item">
            <dt className='item_img'>
                <img src={props.image} alt='image item' />
            </dt>
            <dl className='item_content'>{props.title}</dl>
        </dl>
    )
}