let services_content = {
    consulting: {
        title: 'IT-консалтинг и проектирование IT-архитектуры медицинского бизнеса',
        p: [
            'Команда “IT Doc” имеет богатый опыт в сфере в сфере разработки и проектирования IT-систем для медицинских организаций, а также опыт интеграции IT-решений между собой, обеспечивая бесперебойную работу всего IT-комплекса медицинской организации – от регистратуры до операционной.',
            'Нами спроектированы и запущены IT-архитектуры для нескольких медицинских компаний, а также разработаны и внедрены множество частных решений.',
            'Мы имеем глубокую экспертизу не только как разработчики и интеграторы IT-систем, но и погружены в особенности законодательства в сфере медицины, лицензирования медицинской деятельности и требований к бизнес-процессам и программному обеспечению для клиник. Мы с готовностью делимся своими компетенциями с клиентами в решении тех или иных задач.',
            'В проектировании IT-архитектур мы ориентируемся на оптимальность соотношения «цена-качество» в каждом используемом решении. Поэтому чаще всего ориентируемся на готовые, существующие на рынке продукты, с каждым из которых мы знакомы не понаслышке.',
            'Мы создаем IT-решения, которые помогают врачам принимать решения, а пациентам получать необходимую им медицинскую помощь. Наша цель - сделать медицину более простой, доступной и современной. Мы стремимся к тому, чтобы наши проекты были не только удобными и надежными, но и приносили реальную пользу людям.'
        ],
        img: '/images/services/consulting.png'
    },
    customization: {
        title: 'Разработка и кастомизация программного обеспечения',
        p: [
            'Компания "IT Doc" осуществляет разработку и кастомизацию программного обеспечения для медицинских организаций.',
            'Мы выполняем следующие виды работ:',
            '- анализ бизнес-процессов медицинской организации;\n- проектирование программного обеспечения;\n- разработка программного обеспечения для медицинских организаций;\n- интеграция с информационными системами заказчика;\n- внедрение и сопровождение программного обеспечения;\n- обучение пользователей;\n- корректировка и доработка программного обеспечение по результатам тестирования и опытно-промышленной эксплуатации;\n- модернизация и апгрейд программного обеспечения',
            'Наши преимущества в качестве разработчиков программного обеспечения:',
            '- большой опыт в сфере медицинских IT-систем;\n- экспертиза в медицинском законодательстве и бизнес-процессов медицинских организаций;\n- комплексный подход к решению задач заказчика со знанием множества нюансов в сфере медицины;\n- использование лицензионного программного обеспечения;\n- высокое качество работ;\n- строгое соблюдение сроков и бюджета'
        ],
        img: '/images/services/customization.jpg'
    },
    integration: {
        title: 'Интеграция IT-систем для медицинских организаций на 360° под ключ',
        p: [
            'Интеграция IT-систем для медицинских организаций на 360° под ключПомимо разработки частных решений команда “IT Doc” осуществляет интеграцию IT-систем для медицинских организаций на 360° под ключ. Мы предлагаем полный цикл работ: от проектирования IT-архитектуры до ее полного внедрения. “IT Doc” – это команда профессионалов, имеющих за плечами огромный опыт работы в сфере IT, автоматизации и консалтинга в медицине.',
            'Мы готовы взять на себя все вопросы, связанные с внедрением и сопровождением программной и аппаратной частей IT-архитектуры медицинской организации, а именно:',
            '• Проектирование и разработка IT-архитектуры;\n• Подбор компонентов IT-архитектуры;\n• При необходимости разработка внедрение частного IT-решения;\n• Настройка и интеграция IT-систем и оборудования;\n• Обучение персонала; • Внедрение и сопровождение IT-проектов',
            '“IT Doc” – это команда профессионалов, имеющих за плечами огромный опыт работы в сфере IT, автоматизации и консалтинга в медицине.'
        ],
        img: '/images/services/integration.jpg'
    },
    'app-development': {
        title: 'Разработка медицинских мобильных приложений',
        p: [
            'Компаний IT Doc занимается разработкой мобильных приложений для медицинских организаций.',
            'Мы обладаем большим опытом в разработке именно медицинских мобильных приложений, так как они обладают целым рядом нюансов (связанных, например, с соблюдением требований законодательства о передаче медицинских данных), о которых большинство разработчиков могут не знать.',
            'Разработка мобильных приложений включает в себя такие этапы работ, как:\n- сбор бизнес-требований к приложению;\n- описание функциональных требований;\n- проектирование приложения;\n- написание технического задания и брифа на дизайн;\n- программирование и отрисовка мобильного приложения;\n- тестирование и публикация мобильного приложения',
            'Компания IT Doc обладает опытом и экспертизой как по каждому отдельному этапу разработки, так и в выполнении работ «под ключ».'
        ],
        img: '/images/services/app-development.png'
    },
    'term-development': {
        title: 'Разработка и производство медицинских терминалов',
        p: [
            'Разработка и производство медицинских терминаловКомпания "IT Doc" является проектировщиком, разработчиком и производителем медицинских терминалов различной направленности:',
            '- терминалы предрейсовых медицинских осмотров;\n- терминалы для прохождения профилактических осмотров;\n- информационные киоски (для клиник и аптек) и т.д. Кроме того, мы производим терминалы по индивидуальным проектам наших заказчиков.',
            'По желанию заказчика терминал может комплектоваться любым оборудованием в зависимости от потребностей и задач, стоящих перед терминалом. Все оборудование может быть предоставлено в виде отдельных модулей или в сборе в едином корпусе.',
            'Терминалы могут поставляться как с уже готовым программным обеспечением, являющимся разработкой компании “IT Doc”, так и с разработкой программного обеспечения индивидуально под клиента',
            'При необходимости специалисты компании “IT Doc” могут интегрировать терминалы с предустановленным программным обеспечением с IT-системами заказчика'
        ],
        img: [
            '/images/services/term-development1.jpg',
            '/images/services/term-development2.jpg'
        ]
    },
    outsourcing: {
        title: 'Битрикс24 — полный набор инструментов для организации работы компании',
        p: [
            'Полный комплект инструментов для организации работы. Битрикс24 упрощает рабочие процессы: обсуждение и согласование, контроль задач, работу с клиентами.',
            'Вы можете работать с сервисом в облаке или установить коробочную версию на ваш сервер, чтобы индивидуально настроить бизнес-логику, интерфейс, интегрировать с «1С:ЗУП». Коробочная версия сервиса - это продукт «1С-Битрикс24».'
        ],
        img: '/images/services/bitrix.jpg'
    }
}

export default services_content