import { useState } from "react"
import "../css/imageSlider.scss"

export default function ImageSlider(props) {
    let [imageNum, setImageNum] = useState(0)
    let [openImage, setOpenImage] = useState('slider_modal')
    const toggleSlider = (openClass='') => {
        setOpenImage(`slider_modal${openClass}`)
        document.querySelector('body').classList.toggle('open')
    }
    const goNext = () => {
        imageNum === props.images.length - 1 ? setImageNum(0) : setImageNum(imageNum + 1)
    }
    const goBack = () => {
        console.log(props.images.length)
        imageNum === 0 ? setImageNum(props.images.length - 1) : setImageNum(imageNum - 1)
    }
    return (
        <div style={props.nonStyle} className="service_image product_image slider">
            <div className="arrows">
                <img className="arrow" src="/images/nav-arrow-left.svg" alt="left arrow" onClick={goBack} />
            </div>
            <img onClick={() => toggleSlider(' open')} style={props.styles} className="image_block none" src={props.images[imageNum]} alt={props.name} />
            <div className="arrows">
                <img className="arrow" src="/images/nav-arrow-right.svg" alt="right arrow" onClick={goNext} />
            </div>
            <div className={openImage} onClick={(e) => e.target.className === 'slider_modal open'|| e.target.className === 'slider_modal_content'? toggleSlider() : null}>
                <div className="slider_modal_content">
                    <div className="arrows left_arrow">
                        <img className="arrow" src="/images/nav-arrow-left.svg" alt="left arrow" onClick={goBack} />
                    </div>
                    <img className="modal_img none" src={props.images[imageNum]} alt={props.name} />
                    <div className="arrows right_arrow">
                        <img className="arrow" src="/images/nav-arrow-right.svg" alt="right arrow" onClick={goNext} />
                    </div>
                </div>
            </div>
        </div>
    )
}