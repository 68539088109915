import services_content from "../store/services_content"
import '../css/servicePage.scss'
import { useEffect } from "react"
import ImageSlider from "./imageSlider"

export default function ServicePageSection(props) {
    let content = services_content[props.service]
    useEffect(()=>{
        window.scrollTo(0, 0)
    })
    if(props.service === 'outsourcing'){
        return (
            <section className="service">
                <div className="wrap">
                    <div className="wrap_block">
                        <h2 className="section_title">{content.title}</h2>
                        <div className="outsourcing_block">
                            {content.p.map((elem, i) => (
                                <p key={i} className="section_description">{elem}</p>
                            ))}
                            <img className="service_image" src={content.img} alt={props.service} />
                        </div>
                    </div>
                </div>
            </section>
        )
    } else{
        return (
            <section className="service">
                <div className="wrap">
                    <div className="wrap_block">
                        <h2 className="section_title">{content.title}</h2>
                        <div className="service_block">
                            {content.p.map((elem, i) => (
                                <p key={i} className="section_description">{elem}</p>
                            ))}
                            {Array.isArray(content.img)? <ImageSlider images={content.img} name={props.service} /> : <img className="service_image" src={content.img} alt={props.service} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}