import { useParams } from "react-router-dom"
import ServicePageSection from "../components/servicePageSection"
import FormSection from "../components/formSection"
import { useEffect } from "react"

export default function ServicePage() {
    let { service } = useParams()
    useEffect(()=>{
        document.querySelector('body').classList.remove('open')
      })
    return (
        <>
        <ServicePageSection service={service}/>
        <FormSection />
        </>

    )
}