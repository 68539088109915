const prices = [
    {
        title: 'Стоимость часа работы программистов разной квалификации',
        table: {
            'Стоимость часа работы программиста разной квалификации Junior': '1 500',
            'Стоимость часа работы программиста разной квалификации Middle': '3 000',
            'Стоимость часа работы программиста разной квалификации Senior': '4 000',
            'Стоимость часа работы руководителя проекта': '5 000'
        }
    },
    {
        title: 'Стоимость работ по программному обеспечению 1С',
        table: {
            'Обновление типовой конфигурации 1С 8.3': '2 500',
            'Обновление не типовой (доработанной) конфигурации 1С 8.3': '5 000',
            'Обновление платформы 1С 8.3 предприятие': '1 500',
            'Настройка резервного копирования базы (файловая версия)': '1 000',
            'Настройка резервного копирования базы (SQL версия)': '1 500'
        }
    },
    {
        title: 'ИТ - услуги по компьютерам, ноутбукам, моноблокам',
        table: {
            'Установка Windows Vista/7/10': '1 000',
            'Установка FreeBSD/Linux': '1 200'
        }
    },
    {
        title: 'ИТ - услуги по серверам и сетевому оборудованию',
        table: {
            'Установка, первичная настройка серверной ОС (Windows, Linux)': '3 000',
            'Установка MS SQL или PostgreSql сервера': '1 500',
            'Установка, настройка различных ролей сервера (Domain Controller (Active Directory), DHCP, Remote Access / VPN Server, File Server, FTP Server, Print Server, Terminal Server, Mail Server, RDP)': '1 500'
        }
    },
]

export default prices