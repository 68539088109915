import React, { useRef } from "react";
import burgerMenuStore from "../store/burgerMenuStore";
import { Link} from "react-router-dom";
import '../css/header.scss';
import DesktopMenu from "./desktopMenu";
import MobileMenu from "./mobileMenu";
import { observer } from "mobx-react-lite";

const Header = observer(() => {
    let firstSpan = useRef()
    let secSpan = useRef()
    let thirdSpan = useRef()
    const mobile_menu = ()=>{
        burgerMenuStore.changeOpen()
        firstSpan.current.classList.toggle('open')
        secSpan.current.classList.toggle('open')
        thirdSpan.current.classList.toggle('open')
    }
        return (
            <header>
                <div className="wrap">
                    <div className="header_menu">
                        <div className="header_menu_left">
                            <div className="menu_logo">
                                <Link to='/'><img className="none" src="/images/logo.png" alt="logo" /></Link>
                            </div>
                        </div>
                        <div className="header_menu_right">
                            <div className="mobile_menu_btn" onClick={mobile_menu}>
                                <span ref={firstSpan}></span>
                                <span ref={secSpan}></span>
                                <span ref={thirdSpan}></span>
                            </div>
                            <DesktopMenu />
                            {burgerMenuStore.open? <MobileMenu changeOpen={burgerMenuStore.changeOpen} firstSpan={firstSpan} secSpan={secSpan} thirdSpan={thirdSpan}/>: null}
                        </div>
                    </div>
                </div>
            </header>
        )
})

export default Header