const TmPage = () => {
    document.title = "Заявка в ТП терминалов"
    document.querySelector('meta[name="description"]').setAttribute("content", "Заявка в ТП терминалов");
    return (
        <section className="tm_page">
            <div className="wrap">
                <div className="wrap_block">
                    <div>
                        <script data-b24-form="inline/8/i2s9hz" data-skip-moving="true">
                            {(function (w, d, u) {
                                var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 180000 | 0);
                                var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
                            })(window, document, 'https://cdn-ru.bitrix24.ru/b27200890/crm/form/loader_8.js')}
                        </script>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TmPage