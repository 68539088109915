import { useEffect, useState } from "react";
import ArticleItem from "../components/articleItem";
import '../css/blogPage.scss'
import articles from '../store/articles'

export default function BlogPage() {
    const [categories, setCategories] = useState([])
    const [filter, setFilter] = useState('')
    const [sort, setSort] = useState('desc')
    const [arts, setArts] = useState(articles)
    useEffect(() => {
        let cats = []
        for (let article of articles) {
            if (!(cats.includes(article.category))) {
                cats.push(article.category)
            }
        }
        setCategories(cats)
    }, [])

    useEffect(() => {
        if (filter !== '') {
            setArts(articles.filter(article => article.category == filter))
        } else {
            setArts(articles)
        }
    }, [filter])

    function asc(a, b) {
        let aDates = a.date.split('.')
        let bDates = b.date.split('.')
        if (new Date(aDates[2], aDates[1], aDates[0]) > new Date(bDates[2], bDates[1], bDates[0])) {
            return 1;
        }
        if (new Date(aDates[2], aDates[1], aDates[0]) < new Date(bDates[2], bDates[1], bDates[0])) {
            return -1;
        }
        return 0;
    }

    function desc(a, b) {
        let aDates = a.date.split('.')
        let bDates = b.date.split('.')
        if (new Date(aDates[2], aDates[1], aDates[0]) > new Date(bDates[2], bDates[1], bDates[0])) {
            return -1;
        }
        if (new Date(aDates[2], aDates[1], aDates[0]) < new Date(bDates[2], bDates[1], bDates[0])) {
            return 1;
        }
        return 0;
    }

    const compareFuncs = {
        'desc': desc,
        'asc': asc
    }
    return (
        <section className="blog">
            <div className="wrap">
                <div className="wrap_block">
                    <div className="title">
                        <h2 className='section_title'>Блог</h2>
                        <select value={sort} onChange={(e) => setSort(e.target.value)} className="sort">
                            <option value='desc'>Сначала новые</option>
                            <option value='asc'>Сначала старые</option>
                        </select>
                    </div>
                    <div className="filter_block">
                        {categories.map((category, n) => <div className={`filter_item ${category == filter ? 'active' : ''}`} key={n} tabIndex={n} onClick={() => setFilter(category)}>{category}</div>)}
                    </div>
                    <div className="blog_block">
                        {arts.sort(compareFuncs[sort]).map((article, n) => <ArticleItem article={article} key={n} />)}
                    </div>
                </div>
            </div>
        </section>
    )
}