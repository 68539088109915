import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import articles from '../store/articles'
import '../css/articlePage.scss'
import Navigation from "../components/navigation"

export default function ArticlePage() {
    const { link } = useParams()
    const [curArticle, setCurArticle] = useState([])
    useEffect(() => {
        setCurArticle(articles.find((article) => article.link == link))
    }, [])
    return (
        <section className="article_page">
            <div className='background_img' style={{ background: `no-repeat url(${curArticle.img}) center/cover fixed` }}>
                <div className="wrap">
                    <div className="wrap_block">
                        <h2 className='article_title'>{curArticle.name}</h2>
                    </div>
                </div>
            </div>
            <div className="wrap">
                <div className="wrap_block">
                    <Navigation paths={{ 'Главная': '/', 'Блог': '/blog', [curArticle.name]: `/blog/${curArticle.link}` }} />
                    <div className="article_block">
                        {curArticle.content?.map((item, n) => {
                            if (item.type == 'p') {
                                return <p key={n} className="p">{item.text}</p>
                            }
                            else if (item.type == 'img') {
                                return (
                                    <figure>
                                        <img key={n} src={item.text} />
                                        <figcaption>
                                            <p className="img_title">{item.title}</p>
                                            <p className="img_author">{item.author}</p>
                                        </figcaption>
                                    </figure>
                                )
                            }
                            else if (item.type == 'quote') {
                                return <p key={n} className="quote">{item.text}</p>
                            }
                            else if (item.type == 'title') {
                                return <h3 key={n} className="p_title">{item.text}</h3>
                            }
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}