import '../css/banner.scss';

export default function Banner() {
    return (
        <section className="main_page_banner">
            {/* <div className="banner">
                <div className="wrap">
                    <div className='banner_content'>
                        <h1>Цифровизация медицины. Создание комплексных медицинских информационных систем</h1>
                        <h4>Мы создаем удобные и надежные решения, который приносят реальную пользу людям</h4>
                        <Link to='/#form'><div className='button'>Оставить заявку</div></Link>
                        <Link to='/#about'>
                            <div className='arrow'>
                                <img src='images/arrow.svg' alt='arrow' />
                            </div>
                        </Link>
                    </div>
                </div>
            </div> */}
            <div className='wrap'>
                <div className='banner'>
                    <div className='banner_content'>
                        <div className='banner_content_top'>
                            <h1>Разработка</h1>
                            <img src='images/logo_mini.png' alt='mini logo' />
                        </div>
                        <p className='banner_desc'><span>Go Doc</span>- поликлиника в твоем кармане</p>
                        <div className='apps'>
                            <a target='_blank' href="https://apps.apple.com/ru/app/go-doc-%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D0%B0-%D0%BD%D0%B0-%D0%B4%D0%BE%D0%BC/id6444096154"><img src="/images/appstore.png" alt="App Store" /></a>
                            <a target='_blank' href="https://play.google.com/store/apps/details?id=online.itdoc.client"><img src="/images/googleplay.png" alt="Google Play" /></a>
                        </div>
                    </div>
                    <img className='banner_picture' src='images/banner.png' alt='banner' />
                </div>
            </div>
        </section>
    )
}