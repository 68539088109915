import { Alert, Box, Button, Checkbox, Container, FormControlLabel, Rating, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from "axios";
import { useParams } from "react-router-dom";
import md5 from "md5";

const FormReviewPage = () => {
    const [isDealExist, setIsDealExist] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [rating, setRating] = useState(0);
    const [goodReview, setGoodReview] = useState(true)
    const [review, setReview] = useState('')
    const [error, setError] = useState('')
    const [reopenTask, setReopenTask] = useState(false)
    const { id, pass } = useParams()
    const theme = createTheme({
        palette: {
            primary: {
                light: '#930ee1',
                main: '#6908a1',
                dark: '#48056f',
                contrastText: '#fff',
            },
        },
    });

    useEffect(() => {
        axios
            .get(`/getDeal.php?id=${id}`)
            .then(res => {
                if (res.data !== null && pass == md5(`${id}itdoc`)) {
                    setIsDealExist(true)
                } else {
                    setError('Сделка не найдена!')
                }
            })
            .catch(error => {
                console.log(error)
                setIsDealExist(false)
            })
    }, [])

    useEffect(() => {
        if (rating >= 4) {
            setGoodReview(true)
        } else if (rating != 0) {
            setGoodReview(false)
        }
    }, [rating])

    const submit = () => {
        if (!goodReview) {
            if (review.length < 5) {
                return setError('Заполните обязательное поле!')
            }
        }
        setError('')
        axios
            .post(`/addReviewToDeal.php`, {
                rating,
                review,
                reopenTask,
                id
            })
            .then(res => {
                console.log(res.data)
                setIsSuccess(true)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm">
                <Box sx={{
                    height: '100vh',
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    justifyContent: "center"
                }}
                >
                    {isSuccess &&
                    <Alert variant="filled" severity="success">
                        Ваша оценка отправлена!
                    </Alert>
                    }
                    {error.length > 0 && <Alert severity="error">{error}</Alert>}
                    {isDealExist &&
                        <>
                            <Typography variant="h3" sx={{
                                lineHeight: 1.55,
                                fontSize: 'clamp(1.8rem, 2.5vw, 4rem)',
                                fontWeight: 700,
                                textAlign: "center",
                                color: '#2a053f'
                            }}>
                                Оцените работу
                            </Typography>
                            <Rating
                                name="simple-controlled"
                                value={rating}
                                onChange={(e, newValue) => {
                                    setRating(newValue);
                                }}
                                sx={{
                                    fontSize: '50px'
                                }}
                                size="large"
                            />
                            {goodReview && <TextField id="outlined-basic" label="Комментарий" variant="outlined" multiline rows={4} value={review} onChange={(e) => setReview(e.target.value)} />}
                            {!goodReview
                                &&
                                <>
                                    <TextField id="outlined-basic" label="Что не понравилось?" variant="outlined" error required multiline rows={4} value={review} onChange={(e) => setReview(e.target.value)} />
                                    <FormControlLabel control={<Checkbox value={reopenTask} onChange={() => setReopenTask(!reopenTask)} />} label="Задача не решена" />
                                </>
                            }
                            <Button variant="contained" onClick={submit} sx={{
                                height: 60,
                                fontWeight: 600,
                            }}>
                                Отправить
                            </Button>
                        </>
                    }
                </Box>
            </Container>
        </ThemeProvider>
    )
}

export default FormReviewPage