let products_content = {
    godoc: {
        title: 'Информационная система Go Doc',
        p: [
            'Информационная система Go Doc – это комплексная IT-система для выездных служб медицинской помощи, которая позволяет:',
            '- обрабатывать входящие заявки на вызов врача или медицинской бригады\n- распределять (в ручном или автоматическом режиме) заявки между исполнителями\n- отслеживать в онлайн-режиме количество и статус исполнителей, маршруты их передвижения, статус выполнения каждой конкретной заявки\n- осуществлять зонирование исполнителей\n- осуществлять мониторинг загрузки исполнителей\n- быть на связи с исполнителем в онлайн-режиме посредством встроенного чата по каждой конкретной заявке\n- осуществлять расчет оплаты труда исполнителей\n- осуществлять расчет транспортных расходов исполнителей по геотрекингу\n- составлять и просматривать статистику в разрезе заявок и исполнителей и многое другое.',
            'Информационная система Go Doc работает и как самостоятельное программное обеспечение, так и с мобильными приложениями:\n- Go Doc для врачей – со стороны исполнителя\n- Go Doc для клиента – со стороны клиента'
        ],
        img: [
            '/images/products/godoc1.png',
            '/images/products/godoc2.png',
            '/images/products/godoc3.png',
            '/images/products/godoc4.png',
            '/images/products/godoc5.png',
            '/images/products/godoc6.png',
            '/images/products/godoc7.png',
            '/images/products/godoc8.png'
        ],
        styles: {
            "height": "auto",
            "width": "75%"
        },
        version: '1'
    },
    doctors_godoc: {
        title:'Приложение Go Doc для врачей',
        p:[
            'Приложение Go Doc для врачей – это приложение по работе врача с заявками на оказание медицинской помощи на выезде.\nВ приложении реализованы:\n- интерфейс по просмотру поступивших заявок, начала работы, принятия заявки\n- навигатор на карте для прокладки маршрутов и оценки времени на дорогу\n- предустановленный протокол осмотра с полуавтоматическим заполнением\n- система обратной связи и возможность интеграции с МИС и другими системами\nПриложение Go Doc для врачей может работать как отдельно, так и вместе с информационной системой Go Doc.',
        ],
        img:[
            '/images/products/doctors_godoc1.jpeg',
            '/images/products/doctors_godoc2.jpeg',
            '/images/products/doctors_godoc3.jpeg',
            '/images/products/doctors_godoc4.jpeg',
            '/images/products/doctors_godoc5.jpeg',
        ],
        styles: {
            "border": "2px solid rgb(255, 167, 3)",
            "borderRadius": "10px",
        },
        version: '2'
    },
    clients_godoc: {
        title:'Приложение Go Doc для клиентов',
        p:[
            'Приложение Go Doc – это сервис по заказу медицинских услуг на дом с возможностью отслеживания прибытия медработника в режиме online или получения их в формате дистанционных консультаций.',
            'Функционал приложения состоит из следующих возможностей:\n- вызов врача на дом (терапевт, педиатр, узкие специалисты)\n- вызов на дом медсестры для забора анализов на дому\n- заказ проведения УЗИ и ЭКГ на дому\n- чат с врачом\n- телемедицинские консультации с врачом (терапевт, педиатр, узкие специалисты)\n- психологические консультации online.',
            'В приложение интегрирована карта, на которой можно отслеживать статус передвижения медицинского работника и ориентировочное время прибытия.',
        ],
        img:[
            '/images/products/clients_godoc1.png',
            '/images/products/clients_godoc2.png',
            '/images/products/clients_godoc3.png',
            '/images/products/clients_godoc4.png'
        ],
        styles: {
            "border": "2px solid rgb(255, 167, 3)",
            "borderRadius": "10px",
        },
        version: '3'
    },
    mis_godoc: {
        title:'МИС Go Doc',
        p:[
            'Медицинская информационная система Go Doc – это система, позволяющая автоматизировать документооборот для медицинских организаций.',
            'В МИС Go Doc объединены:\n- электронные медицинские карты пациентов\n- данные по приемам пациентов, поставленным диагнозам и назначенному лечению\n- данные медицинских обследований и анализов в цифровой форме\n- статистика по пациентам и т.д.',
            'Отличительными особенностями МИС Go Doc являются предзагруженные систематизированные справочники диагнозов, лекарств, лекарственных форм, их дозировок и способов применения и ряд других, упрощающих заполнение медицинской документации.'
        ],
        img:[
            '/images/products/mis_godoc1.png',
            '/images/products/mis_godoc2.png',
            '/images/products/mis_godoc3.png',
            '/images/products/mis_godoc4.png',
            '/images/products/mis_godoc5.png',
        ],
        styles: {
            "width": "75%",
            "height": "auto"
        },
        version: '1'
    }
}

export default products_content