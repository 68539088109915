import { useEffect } from 'react'
import '../css/price.scss'
import prices from '../store/prices'
import PriceItem from '../components/priceItem'

export default function PricePage() {
    useEffect(() => {
        document.querySelector('body').classList.remove('open')
        window.scrollTo(0, 0)
    })
    return (
        <section className="price">
            <div className="wrap">
                <div className="wrap_block">
                    <h2 className='section_title'>Стоимость услуг ООО "Ай Ти Док"</h2>
                    <div className='prices'>
                        {
                            prices.map((price, n) => <PriceItem key={n} price={price} />)
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}