import '../css/productPage.scss'
import { useEffect } from "react"
import '../css/terms.scss'
import TermBlock from './termBlock'

export default function TermSection() {
    useEffect(() => {
        document.querySelector('#root').scrollIntoView()
    })
    return (
        <section className="terms">
            <div className="wrap">
                <div className="wrap_block">
                    <h2 className="section_title">Терминалы для предрейсовых медицинских осмотров</h2>
                    <div className="terminals">
                        <p><strong>Дистанционный предрейсовый медицинский осмотр при помощи терминала "IT Doc"</strong> - это полноценная замена обычному предрейсовому медосмотру с возможностью сделать этот процесс <strong>быстрее, проще и дешевле.</strong></p>
                        <p>Терминалы "IT Doc" полностью готовы к работе <strong>сразу после включения</strong>. Процедура прохождения медосмотра занимается <strong>всего пару минут</strong>, а контроль за прохождением предрейсового медосмотра осуществляют дистанционно штатные сертифицированные медработники "IT Doc".</p>
                        <p>Терминалы для предрейсовых медицинских осмотров "IT Doc", которые можно как взять в аренду, так и приобрести в собственность, исполнены <strong>в двух вариантах:</strong></p>
                        <TermBlock/>
                        <p>Основу терминалов составляет <strong>компьютер-моноблок с сенсорным экраном</strong>. Помимо этого, в состав оборудования терминала входят:<br />- видеокамера<br />- пирометр<br />- алкометр<br />- тонометр<br />- принтер для печати этикеток</p>
                        <p>Медицинские услуги по проведению предрейсовых медицинских осмотров оказывают дистанционно <strong>сотрудники клиники «IT Doc»</strong> на основании действующей медицинской лицензии и сертификатов. Прохождение медицинских предрейсовых осмотров на терминалах "IT Doc" <strong>осуществляется круглосуточно.</strong></p>
                        <p>Стоимость терминалов составляет:<br />- настольный вариант: <strong>159 000 руб.</strong><br />- антивандальный вариант: <strong>195 000 руб.</strong><br />Техническая поддержка: <strong>3 000 руб.</strong> в мес.<br />Техническое обслуживание: <strong>2 000 руб.</strong> в мес.<br />Лицензионный сбор: <strong>5 руб.</strong> за каждый осмотр</p>
                        <p>Арендная стоимость содержания терминалов:<br />- настольный вариант: <strong>8 000 руб.</strong> в мес.<br />- антивандальный вариант: <strong>10 000 руб.</strong> в мес.<br />Техническая поддержка: <strong>3 000 руб.</strong> в мес.<br />Техническое обслуживание: <strong>2000 руб.</strong> в мес.<br />Лицензионный сбор: <strong>5 руб.</strong> за каждый осмотр</p>
                        <video controls autoPlay muted loop>
                            <source src='/video/terms.mp4' />
                        </video>
                    </div>
                </div>
            </div>
        </section>
    )
}