import products_content from "../store/products_content"
import '../css/productPage.scss'
import { useEffect } from "react"
import ImageSlider from "./imageSlider"
import TermSection from "./termSection.js"

export default function ProductPageSection(props) {
    let content = products_content[props.product]
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    if (props.product === 'terms') {
        return (
            <TermSection content={content} />
        )
    } else {
        return (
            <section className="product">
                <div className="wrap">
                    <div className="wrap_block">
                        <h2 className="section_title">{content.title}</h2>
                        <div className="product_block">
                            {content.version === '1' ? content.p.map((elem, i) => (<p key={i} style={{ gridColumn: 'span 2' }} className="section_description">{elem}</p>)) : null}
                            {content.version === '2' ? content.p.map((elem, i) => (<p key={i} style={{ gridColumn: "auto", gridRow: "auto" }} className="section_description">{elem}</p>)) : null}
                            {content.version === '3' ? content.p.map((elem, i) => (<p key={i} className="section_description">{elem}</p>)) : null}

                            {props.product === 'clients_godoc' && <div className="extra"><p>Вы можете скачать приложение в...</p><div className="apps"><a href="https://apps.apple.com/ru/app/go-doc-%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D0%B0-%D0%BD%D0%B0-%D0%B4%D0%BE%D0%BC/id6444096154"><img src="/images/appstore.png" alt="App Store"/></a><a href="https://play.google.com/store/apps/det...doc.client"><img src="/images/googleplay.png" alt="Google Play"/></a></div></div>}

                            {content.version === '1' ? <ImageSlider nonStyle={{ gridColumn: "span 2", gridRow: '3' }} styles={content.styles} images={content.img} name={props.product} /> : null}
                            {content.version === '2' ? <ImageSlider nonStyle={{gridColumn: "auto", gridRow: 'auto'}} styles={content.styles} images={content.img} name={props.product} /> : null}
                            {content.version === '3' ? <ImageSlider nonStyle={{}} styles={content.styles} images={content.img} name={props.product} /> : null}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}