import React from 'react';
import '../css/about.scss';

const AboutSection = React.forwardRef((props, ref) => {
    return (
        <section id="about" ref={ref}>
            <div className="wrap">
                <div className="wrap_block">
                    <h2 className='section_title'>О нас</h2>
                    <div className="about_item_block">
                        <p className="section_description">Компания “IT Doc” – это команда специалистов с огромным опытом работы в сфере медицины, понимающая все тонкости и нюансы медицинского бизнеса.</p>
                        <ul className="section_description">Команда “IT Doc” имеет глубокую экспертизу по таким вопросам, как:
                            <li>организационно-административные вопросы, законодательство в сфере медицины и вопросы лицензирования медицинских организаций</li>
                            <li>законодательные и бизнес-требования к программному обеспечению клиник</li>
                            <li>тенденции в сфере IT-технологий в медицине</li>
                            <li>ключевые бизнес-процессы частной медицинской организации</li>
                            <li>существующие варианты наполнения программной и аппаратной частей IT-архитектуры медицинского учреждения на 360°</li>
                            <li>комплексная автоматизация бизнес-процессов частной медицинской организации</li>
                        </ul>
                        <p className="section_description">На сегодняшний день компания IT Doc является зарекомендованным разработчиком и системным интегратором комплексных и частных IT-решений для медицинских организаций.</p>
                    </div>
                </div>
            </div>
        </section>
    )
})

export default AboutSection