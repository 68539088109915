import '../css/serviceItem.scss';

export default function ServiceItem(props) {
    return (
        <dl className="service_item">
            <dt className='item_img'>
                <img src={props.image} alt='image item' />
            </dt>
            <dl className='item_content'>{props.p}</dl>
        </dl>
    )
}