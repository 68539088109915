import { observable, action, makeObservable } from "mobx";

class BurgerMenuStore{
    open = false;
    constructor() {
        this.changeOpen = this.changeOpen.bind(this);
        makeObservable(this, {
            open: observable,
            changeOpen: action
        })
    }
    changeOpen() {
        this.open = !this.open;
      }
}

const burgerMenuStore = new BurgerMenuStore();
export default burgerMenuStore;