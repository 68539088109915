import { useRef } from "react";
import { Link } from "react-router-dom";

export default function MobileMenu(props) {
    let mobile_menu = useRef()
    const close_menu = () => {
        props.changeOpen()
        mobile_menu.current.style.display = "none"
        props.firstSpan.current.classList.toggle('open')
        props.secSpan.current.classList.toggle('open')
        props.thirdSpan.current.classList.toggle('open')
    }
    return (
        <div className="mobile_menu" ref={mobile_menu} onClick={close_menu}>
            <div className="mobile_menu_wrap">
                <nav className="mobile_menu_block">
                <Link to='/#about'><div className="menu">О нас</div></Link>
                <Link to='/#services'><div className="menu">Услуги</div></Link>
                <Link to='/#products'><div className="menu">Продукты</div></Link>
                <Link to='/#form'><div className="menu">Заявка</div></Link>
                <Link to='prices'><div className="menu">Стоимость услуг</div></Link>
                <Link to='contacts'><div className="menu">Контакты</div></Link>
                </nav>
            </div>
        </div>
    )
}