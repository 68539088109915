import { Link } from "react-router-dom"
import products_items from "../store/products"
import ProductItem from "./productItem"
import '../css/products.scss';
import React from "react";

const ProductSection = React.forwardRef((props, ref) => {
    return (
        <section id="products" ref={ref}>
            <div className="wrap">
                <div className="wrap_block">
                    <h2 className='section_title'>Наши продукты</h2>
                    <p className="section_description">Мы готовы предложить Вам наши собственные готовые разработки:</p>
                    <div className="product_item_block">
                        {products_items.map((product, i) => <Link key={i} to={`/products/${product.name}`}><ProductItem image={product.image} title={product.title} /></Link>)}
                    </div>
                </div>
            </div>
        </section>
    )
})

export default ProductSection