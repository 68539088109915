import { useEffect, useRef } from "react";
import AboutSection from "../components/aboutSection";
import Banner from "../components/banner";
import ClientSection from "../components/clientSection";
import FormSection from "../components/formSection";
import ProductSection from "../components/productSection";
import ServiceSection from "../components/serviceSection";
import { useLocation, useNavigate } from "react-router-dom";

export default function MainPage() {
    let loc = useLocation()
    let hash = loc.hash
    let nav = useNavigate()
    let about = useRef()
    let services = useRef()
    let products = useRef()
    let form = useRef()

    useEffect(() => {
        if (hash !== '') {
            let hash_parts = hash.split('#')
            if(hash_parts[1] === 'about'){
                about.current.scrollIntoView({ block: "center" })
            }
            if(hash_parts[1] === 'services'){
                services.current.scrollIntoView({ block: "center" })
            }
            if(hash_parts[1] === 'products'){
                products.current.scrollIntoView({ block: "center" })
            }
            if(hash_parts[1] === 'form'){
                form.current.scrollIntoView({ block: "center" })
            }
            nav('')
        }
    }, [hash, nav])
    useEffect(()=>{
        document.querySelector('body').classList.remove('open')
      })
    return (
        <>

            <Banner />
            <AboutSection ref={about} />
            <ServiceSection ref={services}/>
            <ProductSection ref={products}/>
            <FormSection ref={form}/>
            <ClientSection />
        </>
    )
}