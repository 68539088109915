import ServiceItem from "./serviceItem";
import services_items from '../store/services'
import { Link } from "react-router-dom";
import '../css/services.scss';
import React from "react";

const ServiceSection = React.forwardRef((props, ref) => {
    return (
        <section id="services" ref={ref}>
            <div className="wrap">
                <div className="wrap_block">
                    <h2 className='section_title'>Услуги</h2>
                    <p className="section_description">Компания "IT Doc" – разработчик и системный интегратор комплексных и частных IT-решений для медицинских организаций. К основным направлениям деятельности компании относятся:</p>
                    <div className="service_item_block">
                        {services_items.map((service, i) => <Link key={i} to={`/services/${service.name}`}><ServiceItem image={service.image} p={service.p} /></Link>)}
                    </div>
                </div>
            </div>
        </section>
    )
})

export default ServiceSection