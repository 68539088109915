import '../css/footer.scss';

export default function Footer(){
    return(
        <footer>
        <div className="wrap">
            <div className="footer_block">
                <h4>Наши контакты</h4>
                <div className="contacts">
                    ООО "Ай Ти Док"
                </div>
                <div className="contacts">
                    Московская обл., г. Балашиха,
                    мкр. Железнодорожный, ул. Октябрьская, владение 1, корп. 1, помещение 2
                </div>
                <div>
                    <a href='mailto:info@it-doc.ru'>info@it-doc.ru</a><br />
                    <a href='tel:+78005005791'>+7 (800) 500-57-91</a>
                </div>
                <div className="contacts">
                    ИНН/КПП 5012108833/ 501201001
                </div>
            </div>
            <div className="copyright_sign">
                © IT DOC, 2023
            </div>
        </div>
    </footer>
    )
}