const services_items = [
    {
        name:'consulting',
        image:'images/services/1.svg',
        p:'IT-консалтинг и проектирование IT-архитектуры медицинского бизнеса'
    },
    {
        name:'customization',
        image:'images/services/2.svg',
        p:'Разработка и кастомизация программного обеспечения'
    },
    {
        name:'integration',
        image:'images/services/3.svg',
        p:'Интеграция IT-систем для медицинских организаций на 360° под ключ'
    },
    {
        name:'app-development',
        image:'images/services/4.svg',
        p:'Разработка медицинских мобильных приложений'
    },
    {
        name:'term-development',
        image:'images/services/5.svg',
        p:'Разработка и производство медицинских терминалов'
    },
    {
        name:'outsourcing',
        image:'images/services/6.svg',
        p:'Битрикс24 — полный набор инструментов для организации работы компании'
    }
]

export default services_items