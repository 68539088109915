import terms from "../store/terms"

export default function TermBlock() {
    return (
        <div className='terms_block'>
            {terms.map((term, i) => (
                <div key={i} className={'terms_block_item ' + term["class"]}>
                    <div className='terms_block_img'>
                        <img src={term["img"]} alt='terminal' />
                    </div>
                    <div className='terms_block_p'>
                        <h2>{term["title"]}</h2>
                        <p>{term["p"]}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}