import { Link } from 'react-router-dom'
import '../css/navigation.scss'

const Navigation = (props) => {
    return (
        <div className="navigation">
            {Object.keys(props.paths).map((navItem, n) => <Link key={n} to={props.paths[navItem]} className="nav_item">{navItem}</Link>)}
        </div>
    )
}

export default Navigation