import React, { useState } from 'react';
import '../css/form.scss';

const FormSection = React.forwardRef((props, ref) => {
    let [loading, setLoading] = useState('loading')
    let [disabled, setDisabled] = useState(true)
    let [formData, setFormData] = useState({ name: '', tel: '', email: '' })
    let [formSuccess, setFormSuccess] = useState(false)
    let handleChangeData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        if (formData.name.length > 1 & formData.tel.length > 1 & formData.email.length > 1) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }
    const sendData = async () => {
        await setLoading('loading open')
        await fetch('./data.php', {method: "post", body: JSON.stringify(formData)})
        await setFormSuccess(true)
    }
    if (formSuccess === false) {
        return (
            <section id="form" ref={ref}>
                <div className='form_back'>
                    <div className="wrap">
                        <div className='wrap_form'>
                            <h2>Оставьте заявку для получения индивидуального предложения</h2>
                            <p>С Вами свяжется наш менеджер</p>
                            <div className="form_block">
                                <input type='text' name='name' placeholder='Имя' value={formData.name} onInput={handleChangeData} />
                                <input type='tel' name='tel' placeholder='Телефон' value={formData.tel} onInput={handleChangeData} />
                                <input type='email' name='email' placeholder='e-mail' value={formData.email} onInput={handleChangeData} />
                                <button className='button' onClick={sendData} disabled={disabled}>Отправить заявку</button>
                            </div>
                            <div className={loading}>
                                <img src='./images/spinner.png' alt='spinner' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section id="form" ref={ref}>
                <div className='form_back'>
                    <div className="wrap">
                        <div className='wrap_form'>
                            <img src='/images/check.svg' alt='ok' />
                            <h2>Заявка отправлена</h2>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
})

export default FormSection