import { useParams } from "react-router-dom"
import FormSection from "../components/formSection"
import ProductPageSection from "../components/productPageSection"
import { useEffect } from "react"

export default function ProductPage() {
    let { product } = useParams()
    useEffect(()=>{
        document.querySelector('body').classList.remove('open')
      })
    return (
        <>
        <ProductPageSection product={product} />
        <FormSection />
        </>

    )
}