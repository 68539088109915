import { Link } from "react-router-dom";
import '../css/articleItem.scss'

export default function ArticleItem(props) {
    return (
        <Link to={props.article.link} className="article_link">
            <div className="article">
                <div className="content">
                    <img className='article_img' src={props.article.img.length > 1? props.article.img : "images/logo.png"} alt="logo" />
                    <div className="desc">
                        <span className="category">{props.article.category}</span>
                        <h3 className="title">{props.article.name}</h3>
                        <span className="date">{props.article.date}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}